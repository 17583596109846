import Utils from '../sources/Utils'
import UInt64 from '../externals/UInt64';

//------------------------------------------------------------------------------
const client_remote_operation =
{
    asset_loading_status                : 0,
    update_ar_cameras                   : 1,
    create_controller                   : 2,
    pick_entity                         : 3,
    select_entities                     : 4,
    set_controllers_state               : 5,
    delete_controller                   : 6,
    update_action_map                   : 7,
    update_selection_color              : 8,
    update_camera_controller_settings   : 9,
    physics_raycast                     : 10,
};

//------------------------------------------------------------------------------
const editor_remote_operation =
{
    update_config                           : 0,
    create_entity                           : 1,
    reparent_entity                         : 2,
    update_entities                         : 3,
    remove_components                       : 4,
    delete_entities                         : 5,
    set_visibility                          : 6,
    update_material                         : 7,
    update_volume_mat                       : 8,
    update_data_object                      : 9,
    update_asset                            : 10,
    fire_event                              : 11,
    update_script_data_object               : 12,
    update_render_graph_data_object         : 13,
    update_shader                           : 14,
    json_update_entities                    : 15,
    set_filter                              : 16,
    remove_filter                           : 17,
    toggle_filter                           : 18,
    update_animation_controller_data_object : 19,
    assign_client_uuid_to_script            : 20,
    __unused_1                              : 21,
    __unused_2                              : 22,
    __unused_2                              : 23
};

//------------------------------------------------------------------------------
export const physics_query_filter_flag =
{
    distance_agnostic_block : (1 << 0),
    dynamic_block           : (1 << 1),
    static_block            : (1 << 2),
    record_touches          : (1 << 3)
}

//------------------------------------------------------------------------------
const RTID_SIZE = 4;
const UUID_SIZE = 16;

//------------------------------------------------------------------------------
export default class SDK3DVerse_FTLAPI
{
    //--------------------------------------------------------------------------
    constructor(taskStack)
    {
        this.taskStack = taskStack;
        this.componentSerializer = {};
    }

    //--------------------------------------------------------------------------
    setComponentDescriptions(componentClasses)
    {
        this.componentClasses = componentClasses;
        this.generateSerializers();
    }

    //--------------------------------------------------------------------------
    async castScreenSpaceRay(cameraRTID, x, y, highlight, keepOldSelection)
    {
        const mode = highlight ? (keepOldSelection ? 1 : 2) : 0;

        return new Promise((resolve) =>
        {
            const buffer        = new ArrayBuffer(9 + RTID_SIZE);
            const bufferWriter  = new DataView(buffer);
            bufferWriter.setFloat32(0, x, true);
            bufferWriter.setFloat32(4, y, true);
            bufferWriter.setUint8(8, mode, true);
            Utils.writeRTID(cameraRTID, bufferWriter, 9);

            this.taskStack.pushClientRemoteOperation(
                client_remote_operation.pick_entity,
                new Uint8Array(buffer),
                function(pickingResponse)
                {
                    const dataView  = new DataView(pickingResponse);
                    const rtid      = Utils.readRTID(dataView, 0);

                    if(rtid == 0)
                    {
                        return resolve(
                        {
                            entityRTID      : null,
                            pickedPosition  : null,
                            pickedNormal    : null
                        });
                    }

                    const pickedPosition  = [
                        dataView.getFloat32(4,  true),
                        dataView.getFloat32(8,  true),
                        dataView.getFloat32(12, true)
                    ];

                    const pickedNormal   = [
                        dataView.getFloat32(16, true),
                        dataView.getFloat32(20, true),
                        dataView.getFloat32(24, true)
                    ];

                    resolve(
                    {
                        entityRTID      : rtid.toString(),
                        pickedPosition  : pickedPosition,
                        pickedNormal    : pickedNormal
                    });
                }
            );
        });
    }

    //--------------------------------------------------------------------------
    highlightEntities(RTIDs, keepOldSelection)
    {
        const buffer        = new ArrayBuffer((RTIDs.length * RTID_SIZE) + 1);
        const bufferWriter  = new DataView(buffer);

        bufferWriter.setInt8(0, 0, true);
        for(const i in RTIDs)
        {
            Utils.writeRTID(RTIDs[i], bufferWriter, (i * RTID_SIZE) + 1);
        }

        this.taskStack.pushClientRemoteOperation(client_remote_operation.select_entities, new Uint8Array(buffer));
    }

    //--------------------------------------------------------------------------
    createController(controllerType, entityRTID, isEnabled)
    {
        var buffer          = new ArrayBuffer(RTID_SIZE + 2);
        var bufferWriter    = new DataView(buffer);

        bufferWriter.setUint8(0, controllerType);
        bufferWriter.setUint8(1, isEnabled ? 1 : 0);
        Utils.writeRTID(entityRTID, bufferWriter, 2);
        this.taskStack.pushClientRemoteOperation(client_remote_operation.create_controller, new Uint8Array(buffer));
    }

    //--------------------------------------------------------------------------
    deleteController(controllerID)
    {
        var buffer          = new ArrayBuffer(4);
        var bufferWriter    = new DataView(buffer);

        bufferWriter.setUint32(0, controllerID, true);
        this.taskStack.pushClientRemoteOperation(client_remote_operation.delete_controller, new Uint8Array(buffer));
    }

    //--------------------------------------------------------------------------
    setControllersState(controllersState)
    {
        const CONTROLLER_STATE_SIZE = 5;

        var buffer          = new ArrayBuffer(CONTROLLER_STATE_SIZE * controllersState.length);
        var bufferWriter    = new DataView(buffer);

        for(var i in controllersState)
        {
            bufferWriter.setUint32(i * CONTROLLER_STATE_SIZE, controllersState[i].id, true);
            bufferWriter.setInt8(i * CONTROLLER_STATE_SIZE + 4, controllersState[i].enabled ? 1 : 0);
        }
        this.taskStack.pushClientRemoteOperation(client_remote_operation.set_controllers_state, new Uint8Array(buffer));
    }

    //--------------------------------------------------------------------------
    updateConfig(config)
    {
        var jsonString  = JSON.stringify(config);
        var buf         = new ArrayBuffer(jsonString.length+1);
        var bufView     = new Uint8Array(buf);
        for (var i = 0; i < jsonString.length; i++)
        {
            bufView[i] = jsonString.charCodeAt(i);
        }

        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.update_config, bufView, true);
    }

    //--------------------------------------------------------------------------
    createEntity(entityTemplate, parentLinkerRTID, parentEntityRTID)
    {
        const utf8Encoder           = new TextEncoder();
        const jsonPayload           = JSON.stringify(entityTemplate);
        const encodedJsonPayload    = utf8Encoder.encode(jsonPayload);

        const buf                   = new ArrayBuffer(RTID_SIZE * 2 + encodedJsonPayload.byteLength + 1);
        const bufView               = new Uint8Array(buf);
        const bufferWriter          = new DataView(buf);

        if (parentLinkerRTID)
        {
            Utils.writeRTID(parentLinkerRTID, bufferWriter, 0);
        }

        if (parentEntityRTID)
        {
            Utils.writeRTID(parentEntityRTID, bufferWriter, RTID_SIZE);
        }

        const offset = RTID_SIZE * 2;
        bufView.set(encodedJsonPayload, offset);

        this.taskStack.pushEditorRemoteOperation(
            editor_remote_operation.create_entity,
            bufView
        );
    }

    //--------------------------------------------------------------------------
    deleteEntities(entityRTIDs)
    {
        var buffer          = new ArrayBuffer(RTID_SIZE * entityRTIDs.length);
        var bufferWriter    = new DataView(buffer);

        for(var i in entityRTIDs)
        {
            Utils.writeRTID(entityRTIDs[i], bufferWriter, RTID_SIZE * i)
        }

        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.delete_entities, new Uint8Array(buffer));
    }

    //--------------------------------------------------------------------------
    propagateChanges(entitiesToUpdate)
    {
        const components        = {};
        const serializeInJson   = !this.canSerializeComponentsInBinary(entitiesToUpdate);

        for(const entityRTID in entitiesToUpdate)
        {
            const entity = entitiesToUpdate[entityRTID];
            for(let componentName of entity.dirtyComponents)
            {
                if(!components[componentName])
                {
                    components[componentName] = [];
                }

                const value = entity.getComponent(componentName);
                components[componentName].push(
                {
                    rtid  : entity.getOverriddenEntity().getComponent("euid").rtid,
                    value : serializeInJson ? JSON.stringify(value) : value
                });
            }

            entity.dirtyComponents.length = 0;
        }

        if(Object.keys(components).length === 0)
        {
            return;
        }

        if(serializeInJson)
        {
            this.serializeComponentsInJson(components);
        }
        else
        {
            this.serializeComponentsInBinary(components);
        }
    }

    //-------------------------------------------------------------------------
    canSerializeComponentsInBinary(entitiesToUpdate)
    {
        for(const entityRTID in entitiesToUpdate)
        {
            const entity = entitiesToUpdate[entityRTID];
            for(const componentName of entity.dirtyComponents)
            {
                const value = entity.getComponent(componentName);
                if(value.dataJSON)
                {
                    return false;
                }

                const isComponentUpdateSupported = this.componentSerializer.hasOwnProperty(componentName);
                if(!isComponentUpdateSupported)
                {
                    return false;
                }
            }
        }

        return true;
    }

    //--------------------------------------------------------------------------
    serializeComponentsInBinary(components)
    {
        //==================
        // Size calculation
        //==================
        let BufferSize = 4; //Component count length

        for(const componentName in components)
        {
            const componentArray = components[componentName];

            //Hash + entity count
            BufferSize += 8;

            //Entity Array length
            BufferSize += componentArray.length * RTID_SIZE;

            //Component values
            BufferSize += this.componentClasses[componentName].size * componentArray.length;
        }

        //==================
        // Sending
        //==================
        let offset  =   0;

        let data            = new ArrayBuffer(BufferSize);
        let bufferWriter    = new DataView(data);

        bufferWriter.setUint32(offset, Object.keys(components).length, true);
        offset += 4;

        for(const componentName in components)
        {
            let componentHash   = this.componentClasses[componentName].hash;
            let componentArray  = components[componentName];

            bufferWriter.setUint32(offset, componentHash, true);
            offset += 4;

            bufferWriter.setUint32(offset, componentArray.length, true);
            offset += 4;

            // Entity Array
            for(const { rtid } of componentArray)
            {
                Utils.writeRTID(rtid, bufferWriter, offset);
                offset += RTID_SIZE;
            }

            // Component values
            for(const { value } of componentArray)
            {
                offset += this.componentSerializer[componentName](bufferWriter, value, offset);
            }
        }

        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.update_entities, new Uint8Array(data), true);
    }

    //--------------------------------------------------------------------------
    serializeComponentsInJson(components)
    {
        //==================
        // Size calculation
        //==================
        let BufferSize = 4; //Component count length

        for(const componentName in components)
        {
            const entries = components[componentName];

            // Hash + entity count
            BufferSize += 8;

            // Entity RTIDs + values size length
            BufferSize += entries.length * (RTID_SIZE + 4);

            // Component values
            BufferSize += entries.reduce((accumulator, {value}) => accumulator + value.length, 0);
        }

        //==================
        // Sending
        //==================
        let offset  =   0;

        let data            = new ArrayBuffer(BufferSize);
        let bufferWriter    = new DataView(data);

        bufferWriter.setUint32(offset, Object.keys(components).length, true);
        offset += 4;

        for(const componentName in components)
        {
            let componentHash   = this.componentClasses[componentName].hash;
            let componentArray  = components[componentName];

            bufferWriter.setUint32(offset, componentHash, true);
            offset += 4;

            bufferWriter.setUint32(offset, componentArray.length, true);
            offset += 4;

            // Entity Array
            for(const { rtid } of componentArray)
            {
                Utils.writeRTID(rtid, bufferWriter, offset);
                offset += RTID_SIZE;
            }

            // Component values
            for(const { value } of componentArray)
            {
                bufferWriter.setUint32(offset, value.length, true);
                offset += 4;

                for (let i = 0; i < value.length; i++)
                {
                    bufferWriter.setUint8(i + offset, value.charCodeAt(i));
                }

                offset += value.length;
            }
        }

        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.json_update_entities, new Uint8Array(data), true);
    }

    //--------------------------------------------------------------------------
    updateDataObject(entityRTID, dataObjectValue)
    {
        var jsonString      = JSON.stringify(dataObjectValue);
        var buffer          = new ArrayBuffer(RTID_SIZE + jsonString.length + 1);
        var bufferWriter    = new DataView(buffer);

        Utils.writeRTID(entityRTID, bufferWriter, 0);

        for (var i = 0; i < jsonString.length; i++)
        {
            bufferWriter.setUint8(i + RTID_SIZE, jsonString.charCodeAt(i));
        }

        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.update_data_object, new Uint8Array(buffer), false);
    }

    //--------------------------------------------------------------------------
    updateScriptDataObject(entityRTID, scriptUUID, dataObjectValue)
    {
        var jsonString      = JSON.stringify(dataObjectValue);
        var buffer          = new ArrayBuffer(RTID_SIZE + UUID_SIZE + jsonString.length + 1);
        var bufferWriter    = new DataView(buffer);

        Utils.writeRTID(entityRTID, bufferWriter, 0);
        Utils.serializeUUID(scriptUUID, bufferWriter, RTID_SIZE);

        //JSON
        for (var i = 0; i < jsonString.length; i++)
        {
            bufferWriter.setUint8(i + RTID_SIZE + UUID_SIZE, jsonString.charCodeAt(i));
        }

        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.update_script_data_object, new Uint8Array(buffer), false);
    }

    //--------------------------------------------------------------------------
    updateRenderGraphDataObject(entityRTID, dataObjectValue)
    {
        var jsonString      = JSON.stringify(dataObjectValue);
        var buffer          = new ArrayBuffer(RTID_SIZE + jsonString.length + 1);
        var bufferWriter    = new DataView(buffer);

        Utils.writeRTID(entityRTID, bufferWriter, 0);

        for (var i = 0; i < jsonString.length; i++)
        {
            bufferWriter.setUint8(i + RTID_SIZE, jsonString.charCodeAt(i));
        }

        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.update_render_graph_data_object, new Uint8Array(buffer), false);
    }

    //--------------------------------------------------------------------------
    removeComponents(entitiesToDetachComponentPerHash)
    {
        //==================
        // Size calculation
        //==================
        let bufferSize = 4; //Component count length

        for(const componentHash in entitiesToDetachComponentPerHash)
        {
            const entities = entitiesToDetachComponentPerHash[componentHash];

            //Hash + entity count
            bufferSize += 8;

            //Entity Array length
            bufferSize += entities.length * RTID_SIZE;
        }

        //==================
        // Sending
        //==================
        let offset          = 0;
        let data            = new ArrayBuffer(bufferSize);
        let bufferWriter    = new DataView(data);

        bufferWriter.setUint32(offset, Object.keys(entitiesToDetachComponentPerHash).length, true);
        offset += 4;

        for(const componentHash in entitiesToDetachComponentPerHash)
        {
            const entities = entitiesToDetachComponentPerHash[componentHash];

            bufferWriter.setUint32(offset, componentHash, true);
            offset += 4;

            bufferWriter.setUint32(offset, entities.length, true);
            offset += 4;

            //Entity Array
            for(const entity of entities)
            {
                Utils.writeRTID(entity, bufferWriter, offset);
                offset += RTID_SIZE;
            }
        }

        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.remove_components, new Uint8Array(data));
    }

    //--------------------------------------------------------------------------
    fireEvent(eventMapUUID, eventName, entityRTIDs, dataObject = {})
    {
        const isDataObjectEmpty = Object.keys(dataObject).length == 0;
        const dataObjectString  = JSON.stringify(dataObject);
        const dataObjectLength  = isDataObjectEmpty ? 1 : (dataObjectString.length + 1);

        const buffer            = new ArrayBuffer(
               UUID_SIZE                        // EventMap UUID
             + eventName.length + 1             // Event name + Null terminate
             + dataObjectLength                 // DataObject + Null terminate
             + 4                                // Entity count as Uint32
             + entityRTIDs.length * RTID_SIZE   // Entity RTIDs
        );
        const bufferWriter      = new DataView(buffer);
        let offset              = 0;

        // eventMap UUID
        const eventMapUUIDBuffer = Utils.uuidToUint8Array(eventMapUUID);
        for(let i = 0; i < UUID_SIZE; i++)
        {
            bufferWriter.setUint8(offset++, eventMapUUIDBuffer[i]);
        }

        // Event name
        for (let i = 0; i < eventName.length; i++)
        {
            bufferWriter.setUint8(offset++, eventName.charCodeAt(i));
        }

        // Null terminate eventName
        offset++;

        if(!isDataObjectEmpty)
        {
            for (let i = 0; i < dataObjectString.length; i++)
            {
                bufferWriter.setUint8(offset++, dataObjectString.charCodeAt(i));
            }
        }

        // Null terminate dataObject
        offset++;

        // Entity count
        bufferWriter.setUint32(offset, entityRTIDs.length, true);
        offset += 4;

        // Entity RTIDs
        for(let i in entityRTIDs)
        {
            Utils.writeRTID(entityRTIDs[i], bufferWriter, offset)
            offset += RTID_SIZE;
        }

        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.fire_event, new Uint8Array(buffer));
    }

    //--------------------------------------------------------------------------
    assignClientToScript(clientUUID, scriptUUID, entityRTID)
    {
        // Client UUID + Script UUID + EntityRTID
        const buffer            = new ArrayBuffer(UUID_SIZE * 2 + RTID_SIZE);
        const bufferWriter      = new DataView(buffer);
        let offset              = 0;

        Utils.serializeUUID(clientUUID, bufferWriter, offset);
        offset += UUID_SIZE;

        Utils.serializeUUID(scriptUUID, bufferWriter, offset);
        offset += UUID_SIZE;

        Utils.writeRTID(entityRTID, bufferWriter, offset)
        offset += RTID_SIZE;

        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.assign_client_uuid_to_script, new Uint8Array(buffer));
    }

    //--------------------------------------------------------------------------
    updateActionMap(actionMap)
    {
        var jsonString  = actionMap.serialize();
        var buf         = new ArrayBuffer(jsonString.length+1);
        var bufView     = new Uint8Array(buf);
        for (let i = 0; i < jsonString.length; i++)
        {
            bufView[i] = jsonString.charCodeAt(i);
        }

        this.taskStack.pushClientRemoteOperation(client_remote_operation.update_action_map, bufView);
    }

    //--------------------------------------------------------------------------
    updateAsset(assetType, assetUUID)
    {
        console.log('update-asset', assetType, assetUUID);
        const buffer            = new ArrayBuffer(UUID_SIZE + assetType.length + 1);
        const bufferView        = new Uint8Array(buffer);

        const assetUUIDBuffer   = Utils.uuidToUint8Array(assetUUID);
        let offset              = 0;

        for(let i = 0; i < UUID_SIZE; i++)
        {
            bufferView[offset++] = assetUUIDBuffer[i];
        }

        for (let i = 0; i < assetType.length; i++)
        {
            bufferView[offset++] = assetType.charCodeAt(i);
        }

        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.update_asset, bufferView);
    }

    //--------------------------------------------------------------------------
    updateMaterial(uuid, description)
    {
        this.updateMaterialRequest(
            editor_remote_operation.update_material,
            uuid,
            JSON.stringify(description)
        );
    }

    //--------------------------------------------------------------------------
    updateVolumeMaterial(uuid, description)
    {
        this.updateMaterialRequest(
            editor_remote_operation.update_volume_material,
            uuid,
            JSON.stringify(description)
        );
    }

    //--------------------------------------------------------------------------
    updateMaterialRequest(requestID, assetUUID, description)
    {
        const buffer            = new ArrayBuffer(UUID_SIZE + description.length + 1);
        const bufferView        = new Uint8Array(buffer);

        const assetUUIDBuffer   = Utils.uuidToUint8Array(assetUUID);
        let offset              = 0;

        for(let i = 0; i < UUID_SIZE; i++)
        {
            bufferView[offset++] = assetUUIDBuffer[i];
        }

        for (let i = 0; i < description.length; i++)
        {
            bufferView[offset++] = description.charCodeAt(i);
        }

        this.taskStack.pushEditorRemoteOperation(requestID, bufferView);
    }

    //--------------------------------------------------------------------------
    async updateShader(blob)
    {
        const buffer = await blob.arrayBuffer();
        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.update_shader, new Uint8Array(buffer));
    }

    //--------------------------------------------------------------------------
    updateSelectionColor(hexColor)
    {
        const color         = Utils.hexToRgb(hexColor);
        const intColor      = (color.r) | (color.g << 8) | (color.b << 16);

        const buffer        = new ArrayBuffer(4);
        const bufferWriter  = new DataView(buffer);
        bufferWriter.setUint32(0, intColor, true);

        this.taskStack.pushClientRemoteOperation(client_remote_operation.update_selection_color, new Uint8Array(buffer));
    }

    //--------------------------------------------------------------------------
    updateControllerSettings(config)
    {
        var jsonString  = JSON.stringify(config);
        var buf         = new ArrayBuffer(jsonString.length+1);
        var bufView     = new Uint8Array(buf);
        for (var i = 0; i < jsonString.length; i++)
        {
            bufView[i] = jsonString.charCodeAt(i);
        }

        this.taskStack.pushClientRemoteOperation(client_remote_operation.update_camera_controller_settings, bufView, null, true);
    }

    //--------------------------------------------------------------------------
    readPhysicsLocationHit(dataView, offset)
    {
        const rtid = Utils.readRTID(dataView, offset).toString();

        const position  = [
            dataView.getFloat32(offset+4,  true),
            dataView.getFloat32(offset+8,  true),
            dataView.getFloat32(offset+12, true)
        ];

        const normal  = [
            dataView.getFloat32(offset+16,  true),
            dataView.getFloat32(offset+20,  true),
            dataView.getFloat32(offset+24, true)
        ];

        return { rtid, position, normal };
    }

    //--------------------------------------------------------------------------
    async physicsRaycast(raycastQuery)
    {
        return new Promise((resolve) =>
        {
            const bufferSize =
                12      // origin           : vec3
                + 12    // direction        : vec3
                + 4     // rayLength        : float
                + 2     // filterFlags      : uint16
                + 2;    // maxNumTouches    : uint16

            const buffer            = new ArrayBuffer(bufferSize);
            const bufferWriter      = new DataView(buffer);
            const vec3Serializer    = vectorSerializer(3);
            let offset              = 0;

            vec3Serializer.toBuffer(bufferWriter, raycastQuery.origin, offset);
            offset += 12;

            vec3Serializer.toBuffer(bufferWriter, raycastQuery.direction, offset);
            offset += 12;

            bufferWriter.setFloat32(offset, raycastQuery.rayLength, true);
            offset += 4;

            bufferWriter.setUint16(offset, raycastQuery.filterFlags, true);
            offset += 2;

            bufferWriter.setUint16(offset, raycastQuery.maxNumTouches, true);

            this.taskStack.pushClientRemoteOperation(
                client_remote_operation.physics_raycast,
                new Uint8Array(buffer),
                (physicsRaycastResponse) =>
                {
                    const dataView  = new DataView(physicsRaycastResponse);

                                    // rtid + position + normal
                    const hitSizeInBytes = 4 + 12 + 12;

                    let offset  = 0;
                    const block = this.readPhysicsLocationHit(dataView, offset);
                    offset      += hitSizeInBytes;

                    const touches       = [];
                    const touchesCount  = dataView.getUint16(offset, true);
                    offset              += 2;
                    for(let i = 0; i < touchesCount; ++i)
                    {
                        touches.push(this.readPhysicsLocationHit(dataView, offset));
                        offset += hitSizeInBytes;
                    }

                    resolve(
                    {
                        block,
                        touches,
                    });
                }
            );
        });

    }

    //--------------------------------------------------------------------------
    setFilter(filter)
    {
        const name          = filter.name;
        const value         = JSON.stringify(computeRPN(filter.value));

        const buffer        = new ArrayBuffer(2 + name.length + value.length);
        const bufferWriter  = new DataView(buffer);
        let offset          = 0;

        bufferWriter.setUint8(offset++, name.length);
        bufferWriter.setUint8(offset++, value.length);

        for (let i = 0; i < name.length; i++)
        {
            bufferWriter.setUint8(offset++, name.charCodeAt(i));
        }

        for (let i = 0; i < value.length; i++)
        {
            bufferWriter.setUint8(offset++, value.charCodeAt(i));
        }

        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.set_filter, new Uint8Array(buffer));
    }

    //--------------------------------------------------------------------------
    removeFilter(filter)
    {
        const buffer        = new ArrayBuffer(1 + filter.name.length);
        const bufferWriter  = new DataView(buffer);
        let offset          = 0;

        bufferWriter.setUint8(offset++, filter.name.length);

        for (let i = 0; i < filter.name.length; i++)
        {
            bufferWriter.setUint8(offset++, filter.name.charCodeAt(i));
        }

        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.remove_filter, new Uint8Array(buffer));
    }

    //--------------------------------------------------------------------------
    toggleFilter(filter)
    {
        const {name, isEnabled} = filter;
        const buffer            = new ArrayBuffer(2 + name.length);
        const bufferWriter      = new DataView(buffer);
        let offset              = 0;

        bufferWriter.setUint8(offset++, filter.name.length);

        for (let i = 0; i < filter.name.length; i++)
        {
            bufferWriter.setUint8(offset++, filter.name.charCodeAt(i));
        }

        bufferWriter.setUint8(offset++, isEnabled ? 1 : 0);
        this.taskStack.pushEditorRemoteOperation(editor_remote_operation.toggle_filter, new Uint8Array(buffer));
    }

    //-------------------------------------------------------------------------
    fetchAssetLoadingStatus()
    {
        this.taskStack.pushClientRemoteOperation(editor_remote_operation.asset_loading_status);
    }

    //--------------------------------------------------------------------------
    generateSerializers()
    {
        const blackListedComponents = ['lineage', 'script_element'];

        for(const componentName in this.componentClasses)
        {
            if(blackListedComponents.includes(componentName))
            {
                continue;
            }

            const { mods, binarySupport, attributes : allAttributes, size } = this.componentClasses[componentName];
            if(mods.includes("transient") || !binarySupport)
            {
                continue;
            }

            const attributes = allAttributes.filter(attribute =>
                !attribute.mods ||
                (
                       !attribute.mods.includes("")
                    && !attribute.mods.includes("editor-only")
                    && !attribute.mods.includes("transient")
                )
            );

            if(attributes.length === 0)
            {
                continue;
            }

            const unknownAttributeTypes = attributes.filter(attribute => !attributeSerializers.hasOwnProperty(attribute.type));
            if(unknownAttributeTypes.length > 0)
            {
                for(const unknownAttributeType of unknownAttributeTypes)
                {
                    console.warn(`Cannot serialize type ${unknownAttributeType.type} for attribute ${componentName}.${unknownAttributeType.name}`);
                }

                continue;
            }

            this.componentSerializer[componentName] = this.generateSerializer(componentName, attributes, size);
        }
    }

    //--------------------------------------------------------------------------
    generateSerializer(componentName, attributes, size)
    {
        const serializers = attributes.map((attribute, index) =>
        ({
            attributeName   : attribute.name,
            offset          : attributes.slice(0, index).reduce(
                                (accumulator, v) => accumulator + attributeSerializers[v.type].size, 0
                            ),
            ...attributeSerializers[attribute.type]
        }));

        const computedSize = attributes.reduce(
            (accumulator, v) => accumulator + attributeSerializers[v.type].size, 0
        );

        if(computedSize !== size)
        {
            console.warn(`Invalid size when generating serializer for component ${componentName}, computed size : ${computedSize}, editor size : ${size}`);
        }

        return function(bufferWriter, component, offset)
        {
            for(const serializer of serializers)
            {
                serializer.toBuffer(
                    bufferWriter,
                    component[serializer.attributeName],
                    offset + serializer.offset
                )
            }

            return size;
        };
    }
}

//------------------------------------------------------------------------------
const uint32Serializer = {
    size : 4,
    toBuffer : function (bufferWriter, value, offset)
    {
        bufferWriter.setUint32(offset, value, true);
    }
};

//------------------------------------------------------------------------------
const uuidSerializer = {
    size : 16,
    toBuffer : function (bufferWriter, value, offset)
    {
        Utils.serializeUUID(value, bufferWriter, offset);
    }
};

//------------------------------------------------------------------------------
const vectorSerializer = function(axisCount)
{
    return {
        size : 4 * axisCount,
        toBuffer : function (bufferWriter, value, offset)
        {
            for(let i = 0; i < axisCount; ++i)
            {
                bufferWriter.setFloat32(offset + (4 * i), value[i], true);
            }
        }
    }
};

//------------------------------------------------------------------------------
const attributeSerializers =
{
    "int32_t" : {
        size : 4,
        toBuffer : function (bufferWriter, value, offset)
        {
            bufferWriter.setInt32(offset, value, true);
        }
    },

    "float" : {
        size : 4,
        toBuffer : function (bufferWriter, value, offset)
        {
            bufferWriter.setFloat32(offset, value, true);
        }
    },

    "bool" : {
        size : 1,
        toBuffer : function (bufferWriter, value, offset)
        {
            bufferWriter.setUint8(offset, value ? 1 : 0);
        }
    },

    "json" : {
        size : 0,
        toBuffer : function(bufferWriter, value, offset) {},
    },

    "uint32_t"              : uint32Serializer,
    "VkSampleCountFlagBits" : uint32Serializer,
    "entity_handle"         : uint32Serializer,

    "vec2"                  : vectorSerializer(2),
    "vec3"                  : vectorSerializer(3),
    "vec4"                  : vectorSerializer(4),
    "quaternion"            : vectorSerializer(4),
    "mat4"                  : vectorSerializer(16),

    "uuid"                  : uuidSerializer,
    "scene_ref"             : uuidSerializer,
    "mesh_ref"              : uuidSerializer,
    "material_ref"          : uuidSerializer,
    "texture_ref"           : uuidSerializer,
    "texture2d_ref"         : uuidSerializer,
    "texture3d_ref"         : uuidSerializer,
    "volume_material_ref"   : uuidSerializer,
    "cubemap_ref"           : uuidSerializer,
    "shader_ref"            : uuidSerializer,
    "script_ref"            : uuidSerializer,
    "render_graph_ref"      : uuidSerializer,
    "sound_ref"             : uuidSerializer,
    "animation_graph_ref"   : uuidSerializer,
    "animation_set_ref"     : uuidSerializer,
    "skeleton_ref"          : uuidSerializer,
    "point_cloud_ref"       : uuidSerializer,
    "collision_geometry_ref": uuidSerializer,
}

//------------------------------------------------------------------------------
const op_prio =
{
    "!": 3,
    "&": 2,
    "|": 1,
    "^": 1
};

//------------------------------------------------------------------------------
function is_operator(op)
{
    return op_prio.hasOwnProperty(op);
}

//------------------------------------------------------------------------------
function has_greater_precedence(op1, op2)
{
    return op_prio[op1] > op_prio[op2];
}

//------------------------------------------------------------------------------
function computeRPN(filter)
{
    const tokens    = filter.split(' ');
    const output    = [];
    const operators = [];

    for (const token of tokens)
    {

        switch (token)
        {
            case "!":
            case "&":
            case "|":
            case "^":
            {
                while (operators.length > 0)
                {
                    const op = operators[operators.length - 1];
                    if (!is_operator(op) || has_greater_precedence(token, op))
                    {
                        break;
                    }
                    operators.pop();
                    output.push(op);
                }
                operators.push(token);
            }
            break;
            case "(":
            {
                operators.push(token);
            }
            break;
            case ")":
            {
                while (operators[operators.length - 1] !== '(')
                {
                    output.push(operators.pop());
                }
                console.assert(operators[operators.length - 1] === '(');
                operators.pop();
            }
            break;
            default:
            {
                output.push(token);
            }
            break;
        }
    }

    while (operators.length !== 0)
    {
        const op = operators.pop();
        console.assert(op !== '(');
        output.push(op);
    }

    return output;
}
