//------------------------------------------------------------------------------
import EventEmitter                     from 'events';

//------------------------------------------------------------------------------
import SDK3DVerse_Streamer              from './Streamer';

//------------------------------------------------------------------------------
import SDK3DVerse_EngineAPI             from './EngineAPI';
import SDK3DVerse_ActionMap             from './ActionMap';
import SDK3DVerse_Utils                 from './Utils';
import SDK3DVerse_Decoder               from './Decoder';

//------------------------------------------------------------------------------
import SDK3DVerse_WebAPI_v0             from './web-apis/v0';
import SDK3DVerse_WebAPI_v1             from './web-apis/v1';

//------------------------------------------------------------------------------
import EntityTemplate                   from './EntityTemplate';

//------------------------------------------------------------------------------
import { controller_type }              from './CameraAPI';
import { physics_query_filter_flag }    from './FTLAPI';
import { createLoadingOverlay }         from './LoadingOverlay';

//------------------------------------------------------------------------------
/**
 * Handles the starting and joining of sessions. See the following methods:
 * [startSession]{@link SDK3DVerse#startSession}, [joinSession]{@link SDK3DVerse#joinSession},
 * [joinOrStartSession]{@link SDK3DVerse#joinOrStartSession}. These are the entry points of any 3dverse app.
 *
 * @namespace SDK3DVerse
 */

//------------------------------------------------------------------------------
/**
 * @ignore
 */
class SDK3DVerse
{
    static defaultStreamerConfig = Object.freeze({
        connectionInfo: {},
        display:
        {
            canvas              : null,
            canvasWidth         : 1280,
            canvasHeight        : 720,
            hardwareDecoding    : true,
            hevcSupport         : true,
            upperAlignment      : false
        },

        resolution: [1280, 720],
        viewports : [
            {
                id      : 0,
                left    : 0.0,
                top     : 0.0,
                width   : 1.0,
                height  : 1.0
            }
        ],

        editorTimeout: 2500,
        inputRelayFrequency: 100,
        renderingFrequency: 60,
        defaultCameraSpeed: 'auto'
    });

    EntityTemplate              = EntityTemplate;
    controller_type             = controller_type;

    /** @private */
    createLoadingOverlay        = createLoadingOverlay;

    /**
     * Readonly object which contains the bit flags used to apply a filter on physics bodies hit by [physicsRaycast]{@link SDK3DVerse.engineAPI#physicsRaycast}.
     *
     * A physics body encountered by a ray can be either considered as:
     * - a **block** (stopping the ray when the ray hits it), or
     * - a **touch** (non-blocking, i.e. allowing the ray to pass through it)
     *
     * The flags specify which physics body types block the ray, and whether the non-blocking body types should be returned as touches in the [PhysicsRaycastResult]{@link PhysicsRaycastResult}.
     * There are two physics body types considered:
     * - **static** (for static bodies), and
     * - **dynamic** (for rigid bodies and kinematic rigid bodies)
     * @readonly
     * @type {object} {SDK3DVerse.PhysicsQueryFilterFlag}
     * @property {number} dynamic_block=2 - Dynamic bodies are blocking
     * @property {number} static_block=4 - Static bodies are blocking
     * @property {number} distance_agnostic_block=1 - This is a performance hint. Specifies there is no need to look for the closest block - any
     *                                              blocking body along the ray's path is returned as block. Touches aren't recorded
     * @property {number} record_touches=8 - Ray records non-blocking bodies on its path as touches. Ignored if specified with distance_agnostic_block
     *
     * @see [physicsRaycast]{@link SDK3DVerse.engineAPI#physicsRaycast}
     * @see [PhysicsQueryFilterFlags]{@link PhysicsQueryFilterFlags}
     *
     * @example
     * SDK3DVerse.PhysicsQueryFilterFlag.dynamic_block
     */
    get PhysicsQueryFilterFlag() {
        return Object.freeze(physics_query_filter_flag);
    }

    /**
     * Readonly object which contains the available camera controller types.
     *
     * For more information on the navigation controls of these controllers, use the Scene Editor,
     * or check the [Scene Editor doc]{@link https://docs.3dverse.com/docs/editor/scene-editor}.
     * The 'Enable Camera Orbit' icon in the Scene Editor's Canvas toolbar can be toggled
     * to switch between the Orbital and Editor camera controllers. The 'Help' icon in the Canvas
     * toolbar provides more information on the navigation controls.
     *
     * @readonly
     * @type object
     * @property {int} none=-1
     * @property {int} orbit=1 Orbital camera controller. Camera rotates around point that is clicked but can also move around in 3D world
     * @property {int} editor=4 Editor camera controller. Camera moves freely in 3D world and can look around. It's the default camera controller
     * @example SDK3DVerse.cameraControllerType.editor
     *
     * @see [setViewports]{@link SDK3DVerse.engineAPI.cameraAPI#setViewports}
     * @see [setControllerType]{@link Viewport#setControllerType}
     */
    get cameraControllerType() {
        return Object.freeze(controller_type);
    }

    //--------------------------------------------------------------------------
    /**
     * @hideconstructor
     */
    constructor()
    {
        console.log('SDK3DVerse v' + this.getVersion());

        this.extensions         = {};
        this.extensionMap       = new Map();

        this.notifier           = new EventEmitter();
        this.webAPI             = new SDK3DVerse_WebAPI_v0();
        this.streamer           = new SDK3DVerse_Streamer(this.notifier);
        this.engineAPI          = new SDK3DVerse_EngineAPI(this.notifier, this.streamer);
        this.actionMap          = new SDK3DVerse_ActionMap(this.engineAPI.ftlAPI);

        this.utils              = SDK3DVerse_Utils;

        this.streamerStarted    = false;
        this.resetConnectedState();

        this.resolveEnvironmentVariables();
        this.setupDefaultConfig();

        this.notifier.on("onConnectionClosed", this.onClose);

        this.resizeObserver = null;
    }

    //------------------------------------------------------------------------------
    /**
     * Set canvas element, used to display streamed frames.
     *
     * @param {HTMLCanvasElement} canvas - The [HTMLCanvasElement]{@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement} used to display streamed frames
     *
     * @example
     * SDK3DVerse.setCanvas(document.getElementById('my-canvas-id'));
     *
     * @method SDK3DVerse#setCanvas
     */
    setCanvas(canvas)
    {
        this.streamerConfig.display.canvas  = canvas;
        this.engineAPI.canvas               = canvas;

        this.engineAPI.cameraAPI.setupDisplay(canvas);
        this.streamer.setupDisplay(canvas);
    }

    //------------------------------------------------------------------------------
    // deprecated
    setupDisplay(canvasElement)
    {
        this.setCanvas(canvasElement);
    }

    //------------------------------------------------------------------------------
    /**
     * @private
     *
     * Set the current web API version
     * @param {string} apiVersion - API version to use
     *
     * @method SDK3DVerse#setApiVersion
     */
    setApiVersion(apiVersion)
    {
        switch(apiVersion)
        {
            case 'v0':
                this.webAPI = new SDK3DVerse_WebAPI_v0(this.defaultApiURL);
                this.webAPI.setApiToken(this.userToken);
                break;
            case 'v1':
                this.webAPI = new SDK3DVerse_WebAPI_v1(this.defaultApiURL);
                this.webAPI.setUserToken(this.userToken);
                break;
            default:
                throw new Error('Unknown API version: ' + apiVersion);
        };
    }

    //------------------------------------------------------------------------------
    /**
     * Set resolution of display area in real-time.
     *
     * @param {number} width  - Canvas width in pixels
     * @param {number} height - Canvas height in pixels
     * @param {number} streamingScale=1.0 - Scale applied on width and height of streamed frame.
     *                              The dimensions of the streamed frame received by the SDK client
     *                              will be width\*scale x height\*scale, but the canvas
     *                              frame will be width x height.
     *
     * @method SDK3DVerse#setResolution
     */
    setResolution(width, height, streamingScale = 1.0)
    {
        if(typeof width !== 'number' || typeof height !== 'number' || typeof streamingScale !== 'number')
        {
            throw new Error('Invalid resolution parameter types:', width, height, streamingScale);
        }

        const requestedResolution   = this.utils.alignForYUV(width, height, this.streamerConfig.display.upperAlignment);

        const scaledWidth           = width * streamingScale;
        const scaledHeight          = height * streamingScale;
        const scaledResolution      = this.utils.alignForYUV(scaledWidth, scaledHeight, this.streamerConfig.display.upperAlignment);

        if(!this.streamerStarted)
        {
            this.streamerConfig.resolution              = [scaledResolution.width, scaledResolution.height];
            this.streamerConfig.display.canvasWidth     = requestedResolution.width;
            this.streamerConfig.display.canvasHeight    = requestedResolution.height;
            return requestedResolution;
        }

        this.connectedPromise.then(() =>
        {
            this.streamer.resize(scaledResolution, requestedResolution);
        });

        return requestedResolution;
    }

    //------------------------------------------------------------------------------
    // private
    async setViewports(viewports)
    {
        if(!this.streamerStarted)
        {
            this.streamerConfig.viewports = viewports;
            return;
        }

        await this.connectedPromise;
        await this.engineAPI.cameraAPI.setViewports(viewports);
    }

    //------------------------------------------------------------------------------
    // deprecated, see cameraAPI.setMainCamera
    async setMainCamera(cameraEntity)
    {
        if(!cameraEntity.isAttached('camera'))
        {
            console.warn('Entity does not have a camera component');
            return;
        }

        const viewports = [
            {
                id: 0,
                left: 0,
                top: 0,
                width: 1,
                height: 1,
                camera: cameraEntity,
            },
        ];
        await this.setViewports(viewports);
    }

    //------------------------------------------------------------------------------
    /**
     * Set frequency at which inputs (keyboards, gamepads, touchscreen...) are relayed to the renderer.
     * Without calling this function, the default input relay frequency is 100 ms.
     *
     * @param {number} inputRelayFrequency  - Input relay frequency in milliseconds
     *
     * @method SDK3DVerse#setInputRelayFrequency
     */
    setInputRelayFrequency(inputRelayFrequency)
    {
        this.streamerConfig.inputRelayFrequency = inputRelayFrequency;
    }

    //------------------------------------------------------------------------------
    /**
     * Sets a callback that is called when the client is considered inactive (if the client
     * hasn't relayed any inputs for five minutes).
     *
     * @param {InactivityCallback} callback - Inactivity callback whose functionality determines
     *                                      whether or not to disconnect client from session once the client has become inactive.
     *                                      See code example below for more information.
     *
     *
     * @example
     * SDK3DVerse.setInactivityCallback((resumeCallback) =>
     * {
     *      const answer = prompt("Are you still there?");
     *      if(answer === "yes")
     *      {
     *          // Client has 30 seconds for resumeCallback() to be called before
     *          // they will be automatically disconnected.
     *          resumeCallback();
     *      }
     *      else
     *      {
     *          console.log("Client will be disconnected from session");
     *      }
     * });
     *
     * @method SDK3DVerse#setInactivityCallback
     */
    setInactivityCallback(callback)
    {
        this.streamer.inactivityWarningCallback = callback;
    }

    //------------------------------------------------------------------------------
    /**
     * Set to true to force the canvas to align on the next largest dimension during
     * a [setResolution]{@link SDK3DVerse#setResolution}.
     * In that case a container that will center the canvas (as shown in the sample [here]{@link https://docs.3dverse.com/sdk/})
     * and hide the overflow is suggested. See example below for recommended styling of the container.
     *
     * @example
     * .canvas-container
     * {
     *     position: relative;
     *     display: flex;
     *     flex-direction: column;
     *     justify-content: center;
     *     overflow: hidden;
     * }
     *
     * @param {boolean} value
     *
     * @method SDK3DVerse#setUpperAlignment
     */
    setUpperAlignment(value)
    {
        this.streamerConfig.display.upperAlignment = value;
    }

    //------------------------------------------------------------------------------
    /**
     * @private
     *
     * Start the streaming engine to receive real time rendering data
     *
     * @param {object} connectionInfo  - Connection information returned by [startSession]{@link SDK3DVerse_WebAPI#startSession} or [joinSession]{@link SDK3DVerse_WebAPI#joinSession}
     * @param {boolean} [hardwareDecoding] - Set to true to enable hardware decoding of video streams
     *
     * @method SDK3DVerse#startStreamer
     */
    async startStreamer(connectionInfo, hardwareDecoding = true)
    {
        if(this.streamerStarted)
        {
            console.warn('Streamer already started');
            return;
        }

        this.notifier.on("onConnectionClosed", this.onClose);

        this.streamerStarted = true;

        this.engineAPI.initialize();

        this.streamerConfig.connectionInfo           = connectionInfo;
        this.streamerConfig.display.hardwareDecoding = hardwareDecoding;
        if(!this.streamerConfig.connectionInfo.hasOwnProperty('useSSL'))
        {
            this.streamerConfig.connectionInfo.useSSL = location.protocol === 'https:';
        }
        await this.validateConfig();

        await this.streamer.start(this.streamerConfig, false);
        await this.applyDefaultCameraSpeed();
        this.onConnectedCallback();

        // In standalone, offline camera trigger immediately.
        if(this.streamerConfig.connectionInfo.standalone)
        {
            this.streamerConfig.editorTimeout = 0;
        }

        this.engineAPI.cameraAPI.setEditorTimeout(this.streamerConfig.editorTimeout);
        this.engineAPI.cameraAPI.setViewports(this.streamerConfig.viewports);
    }

    //------------------------------------------------------------------------------
    applyDefaultCameraSpeed = async () =>
    {
        try
        {
            if(!this.webAPI.apiToken && !this.webAPI.userToken)
            {
                return;
            }

            const { defaultCameraSpeed } = this.streamerConfig;

            const desiredSpeed  = defaultCameraSpeed === 'auto'
                                ? await this.getCameraSpeedFromSceneAABB()
                                : defaultCameraSpeed;

            if(desiredSpeed < 0)
            {
                return;
            }

            const speed = Math.max(desiredSpeed, 0.1);
            this.engineAPI.cameraAPI.updateControllerSettings({ speed });
        }
        catch(error)
        {
            console.warn('Failed to apply default camera speed', error);
        }
    }

    //------------------------------------------------------------------------------
    async watchSession(connectionInfo, hardwareDecoding = true)
    {
        this.streamerConfig.connectionInfo = connectionInfo;

        this.streamerStarted = true;
        this.streamerConfig.display.hardwareDecoding = hardwareDecoding;
        await this.validateConfig();

        await this.streamer.start(this.streamerConfig, true);

        if(this.pendingClientUUID)
        {
            this.streamer.subscribeTo(this.pendingClientUUID);
            this.pendingClientUUID = null;
        }
    }

    //------------------------------------------------------------------------------
    subscribeTo(clientUUID)
    {
        if(!this.isRendererConnected())
        {
            this.pendingClientUUID = clientUUID;
            return;
        }

        this.streamer.subscribeTo(clientUUID);
    }

    //------------------------------------------------------------------------------
    /**
     * @private
     *
     * Connect to the editor to offer edition capabilities
     * @param {string} [editorURL] - Default to 'wss://api.3dverse.com/editor-backend/'
     *
     * @method SDK3DVerse#connectToEditor
     * @async
     */
    async connectToEditor(editorURL)
    {
        if(this.editorStarted)
        {
            console.warn('Editor is already in connecting state');
            return;
        }

        this.editorStarted = true;

        await this.onConnected();

        this.engineAPI.editorAPI.connect(
            editorURL || this.defaultEditorURL,
            this.streamerConfig.connectionInfo.sessionKey,
            this.streamer.clientUUID,
            this.getVersion()
        );

        await this.onEditorConnected();
    }

    //------------------------------------------------------------------------------
    /**
     * Check if the renderer is connected.
     *
     * @returns {boolean}
     *
     * @method SDK3DVerse#isRendererConnected
     */
    isRendererConnected()
    {
        return this.streamer.isConnected;
    }

    //------------------------------------------------------------------------------
    // deprecated, check isRendererConnected
    isConnected()
    {
        return this.isRendererConnected();
    }

    //------------------------------------------------------------------------------
    /**
     * A promise which will resolve once the renderer has connected. The renderer
     * must be connected before using methods from [cameraAPI]{@link SDK3DVerse.engineAPI.cameraAPI}
     * or [engineAPI]{@link SDK3DVerse.engineAPI}.
     *
     * @returns {Promise} Promise object.
     *
     * @example
     * await SDK3DVerse.onRendererConnected();
     *
     * @method SDK3DVerse#onRendererConnected
     * @async
     */
    onRendererConnected()
    {
        return this.connectedPromise;
    }

    //------------------------------------------------------------------------------
    // deprecated, use this.onRendererConnected
    onConnected()
    {
        return this.connectedPromise;
    }

    //------------------------------------------------------------------------------
    /**
     * A promise which will resolve once the editor has connected. The editor must
     * be connected before proceeding with any operations that involve {@link Entity}
     * objects.
     *
     * @returns {Promise} Promise object.
     *
     * @example
     * await SDK3DVerse.onEditorConnected();
     *
     * @method SDK3DVerse#onEditorConnected
     * @async
     */
    onEditorConnected()
    {
        return this.engineAPI.editorAPI.onConnected;
    }

    //------------------------------------------------------------------------------
    // private
    async validateConfig()
    {
        this.checkParameter(this.streamerConfig.connectionInfo, "ip");
        this.checkParameter(this.streamerConfig.connectionInfo, "port");
        this.checkParameter(this.streamerConfig.connectionInfo, "sessionKey", "standalone");
        await SDK3DVerse_Decoder.ensureConfig(this.streamerConfig.display);
    }

    //------------------------------------------------------------------------------
    // private
    setupDefaultConfig()
    {
        this.streamerConfig = structuredClone(SDK3DVerse.defaultStreamerConfig);
    }

    //------------------------------------------------------------------------------
    // private
    checkParameter(options, ...parameterNames)
    {
        for(const parameterName of parameterNames)
        {
            if(options[parameterName])
            {
                return;
            }
        }

        throw `Missing mandatory parameter ${parameterNames.toString()}`;
    }

    //------------------------------------------------------------------------------
    /**
     * Installs one or more of the available Livelink.js SDK extensions.
     *
     * @param {SDK3DVerse_ExtensionInterface} extensionClass  - [SDK3DVerse_Gizmos_Ext]{@link SDK3DVerse_Gizmos_Ext}  / [SDK3DVerse_LabelDisplay_Ext]{@link SDK3DVerse_LabelDisplay_Ext}  / [SDK3DVerse_ClientDisplay_Ext]{@link SDK3DVerse_ClientDisplay_Ext}
     * @param {object} parameters - Extension parameters, see documentation for each extension.
     *
     * @returns {SDK3DVerse_ExtensionInterface} Instantiated extension.
     *
     * @method SDK3DVerse#installExtension
     * @async
     */
    installExtension(extensionClass, ...parameters)
    {
        if(this.extensionMap.has(extensionClass))
        {
            return this.extensionMap.get(extensionClass);
        }

        const extension = new extensionClass(this);
        this.extensions[extension.name] = extension;

        const installPromise = this.onConnected()
            .then(
                () => extension.onInit(...parameters)
            ).then(
                () => extension
            );

        this.extensionMap.set(extensionClass, installPromise);
        return installPromise;
    }

    //------------------------------------------------------------------------------
    /**
     * @private
     *
     * Get the down state of the specified keyboard key.
     *
     * @param {string} key  Character key
     *
     * @returns {object} value.down - True if key is pressed, false otherwise.
     *
     * @method SDK3DVerse#getKey
     */
    getKey(key)
    {
        const keyCode = key.charCodeAt(0);
        return {
            down    : this.streamer.inputRelay.keys.hasOwnProperty(keyCode)
                    ? this.streamer.inputRelay.keys[keyCode].down
                    : false
        };
    }

    //------------------------------------------------------------------------------
    /**
     * Get UUID of current client.
     *
     * @returns {string} The current client's UUID.
     *
     * @example
     * const clientUUID = SDK3DVerse.getClientUUID();
     *
     * @method SDK3DVerse#getClientUUID
     */
    getClientUUID()
    {
        return this.streamer.clientUUID;
    }

    //------------------------------------------------------------------------------
    /**
     * Get the UUIDs of all clients in session, including the current client.
     * The first UUID returned is that of the current client.
     *
     * @returns {Array.<string>} Array of client UUIDs.
     *
     * @method SDK3DVerse#getClientUUIDs
     */
    getClientUUIDs()
    {
        return [this.getClientUUID(), ...this.engineAPI.cameraAPI.getClientUUIDs()];
    }

    //------------------------------------------------------------------------------
    /**
     * Enable input events on the canvas (mouse/keyboard/gamepad). This should be used
     * after an explicit call to [disableInputs]{@link SDK3DVerse#enableInputs} as inputs are enabled by default.
     *
     * @example
     * SDK3DVerse.enableInputs();
     *
     * @method SDK3DVerse#enableInputs
     */
    enableInputs()
    {
        this.streamer.inputRelay.resumeInputs();
    }

    //------------------------------------------------------------------------------
    /**
     * Disable input events on canvas (mouse/keyboard/gamepad). This will impact [onMouseEvent]{@link SDK3DVerse.engineAPI.cameraAPI#onMouseEvent},
     * and disable any [camera controller]{@link SDK3DVerse#cameraControllerType} that is used by a {@link Viewport} camera.
     *
     * @example
     * SDK3DVerse.disableInputs();
     *
     * @method SDK3DVerse#disableInputs
     */
    disableInputs()
    {
        this.streamer.inputRelay.suspendInputs();
    }

    //--------------------------------------------------------------------------
    // deprecated, check cameraAPI.updateControllerSettings
    async updateControllerSetting(config)
    {
        await this.onConnected();
        this.engineAPI.cameraAPI.updateControllerSettings(config);
        this.engineAPI.ftlAPI.updateControllerSettings(config);
    }

    //----------------------------------------------------------------------------------------------
    /**
     * Get the default speed for the camera controller based on its type and the scene AABB diagonal (max distance) of
     * a scene and the camera controller type of the viewport.
     * This function does not apply to [cameraControllerType.none & fps]{@link SDK3DVerse#cameraControllerType})
     * @private
     *
     * @param {string} [sceneUUID=the current scene UUID] The unique identifier of the scene to fetch AABB from
     * @param {object} [options] options
     * @param {uint} [options.viewportId=this.cameraAPI.currentViewportEnabled.getId() or 0] The viewport ID from which to get the controller type
     * @param {float} [options.baseSpeed=0.03] The base speed in meters per second to apply for a scene AABB of 1 cube meter for the editor camera controller
     *
     * @method SDK3DVerse#getCameraSpeedFromSceneAABB
     */
    getCameraSpeedFromSceneAABB = async (sceneUUID, options) =>
    {
        const aabb = await this.webAPI.getSceneAABB(sceneUUID);
        return this.getCameraSpeedFromAABB(aabb, options);
    };

    //------------------------------------------------------------------------------
    /**
     * Get the default speed for the camera controller based on an AABB diagonal (max distance) and
     * the camera controller type of the viewport.
     * This function does not apply to [cameraControllerType.none & fps]{@link SDK3DVerse#cameraControllerType})
     * @private
     *
     * @param {AABB} aabb Axis Aligned Bounding Box
     * @param {object} [options] options
     * @param {uint} [options.viewportId=this.cameraAPI.currentViewportEnabled.getId() or 0] The viewport ID from which to get the controller type
     * @param {float} [options.baseSpeed=0.03] The base speed in meters per second to apply for an AABB of 1 cube meter for the editor camera controller
     *
     * @method SDK3DVerse#getCameraSpeedFromAABB
     */
    getCameraSpeedFromAABB = async (aabb, { baseSpeed = 0.03, viewportId = -1 } = {}) =>
    {
        if(!this.utils.isAABBValid(aabb))
        {
            return -1;
        }

        const { currentViewportEnabled } = this.engineAPI.cameraAPI
        viewportId = viewportId === -1 ? currentViewportEnabled?.getId() || 0 : viewportId;
        let controllerType = this.engineAPI.cameraAPI.getControllerType(viewportId);
        if(controllerType === null)
        {
            const firstViewport = this.streamerConfig.viewports?.[viewportId];
            controllerType = (firstViewport?.defaultControllerType) || this.controller_type.editor;
        }

        let speedInMps = 0;
        switch(controllerType)
        {
            default:
            case this.controller_type.none:
            case this.controller_type.fps:
                // fps: speedInMps setting has no effect on the controller (ctrl+wheel change the speedInMps)
                return -1;
            case this.controller_type.editor:
                speedInMps = baseSpeed;
                break;
            case this.controller_type.orbit:
                speedInMps = baseSpeed * 2;
                break;
            case this.controller_type.fixed:
                speedInMps = baseSpeed * 8;
                break;
        }

        speedInMps = this.utils.getDiagonalFromAABB(aabb) * speedInMps;

        //console.debug(`SDK3DVerse.getCameraSpeedFromAABB aabb=${JSON.stringify(aabb)} speedInMps=${speedInMps}`);
        return speedInMps;
    }

    //------------------------------------------------------------------------------
    resolveEnvironmentVariables()
    {
        const urlParams = new URLSearchParams(window.location.search);

        if('3dverse-editor-backend-url' in localStorage)
        {
            this.defaultEditorURL = localStorage.getItem('3dverse-editor-backend-url');
        }
        else
        {
            this.defaultEditorURL = 'wss://api.3dverse.com/editor-backend/';
        }

        if('3dverse-api-gateway-url' in localStorage)
        {
            this.defaultApiURL = localStorage.getItem('3dverse-api-gateway-url');
        }

        if(urlParams.has('3dverseApiToken'))
        {
            this.userToken = urlParams.get('3dverseApiToken');
        }
        else if('3dverse-api-token' in localStorage)
        {
            this.userToken = localStorage.getItem('3dverse-api-token');
        }

        if(this.defaultApiURL)
        {
            this.webAPI.setURL(this.defaultApiURL);
        }

        if(this.userToken)
        {
            this.webAPI.apiToken = this.userToken;
        }
    }

    //------------------------------------------------------------------------------
    /**
     * Get the Livelink.js SDK version.
     *
     * @return {string} Livelink.js SDK version.
     * @method SDK3DVerse#getVersion
     */
    getVersion()
    {
        return process.env.SDK3DVERSE_VERSION;
    }

    //------------------------------------------------------------------------------
    resetConnectedState()
    {
        this.streamerStarted = false;
        this.editorStarted = false;
        this.connectedPromise = new Promise((resolve) =>
        {
            this.onConnectedCallback = resolve;
        });
    }

    //------------------------------------------------------------------------------
    async uninstallAllExtensions()
    {
        const installedExtensionsInOrder = Array.from(this.extensionMap.values());
        const installedExtensionsInReverseOrder = installedExtensionsInOrder.reverse();

        const extensions = await Promise.all(installedExtensionsInReverseOrder);

        for(const extension of extensions)
        {
            await extension.dispose();
        }

        this.extensions = {};
        this.extensionMap.clear();
    }

    //------------------------------------------------------------------------------
    /**
     * Disconnect from the session that was previously started or joined with one of the
     * following calls: [startSession]{@link SDK3DVerse#startSession}, [joinSession]{@link SDK3DVerse#joinSession},
     * [joinOrStartSession]{@link SDK3DVerse#joinOrStartSession}. Note: this only removes
     * the current client from the ongoing session.
     *
     * If there are other clients in the session, the session will still run, but if
     * the session does not have any other clients in it, it will close automatically.
     *
     * @example
     * await SDK3DVerse.disconnectFromSession();
     *
     * @fires onConnectionClosed
     *
     * @async
     *
     * @method SDK3DVerse#disconnectFromSession
     */
    async disconnectFromSession()
    {
        await this.onClose();
        this.notifier.emit("onConnectionClosed");
        this.engineAPI.editorAPI.resetConnectedPromise();
    }

    //------------------------------------------------------------------------------
    // deprecated
    async close()
    {
        await this.disconnectFromSession();
    }

    //------------------------------------------------------------------------------
    onClose = async () =>
    {
        this.resetConnectedState();
        this.notifier.removeAllListeners();

        this.engineAPI.reset();
        this.streamer.stop();

        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }

        await this.uninstallAllExtensions();
    }

    //------------------------------------------------------------------------------
    /**
     * Get the list of sessions already open for a given scene. Can be used to get
     * the session id of a particular session you want to join using [joinSession]{@link SDK3DVerse#joinSession}.
     *
     * @param {object} params
     * @param {string} params.userToken 3dverse user token or public token
     * @param {string} params.sceneUUID UUID of scene
     * @returns {SessionInfo[]} Array of {@link SessionInfo}. Each {@link SessionInfo} element pertains to a session open on the scene with `params.sceneUUID`.
     *
     * @example
     * const sessions = await SDK3DVerse.findSessions({userToken : publicToken, sceneUUID});
     * if(sessions.length > 0)
     * {
     *      const sessionId = sessions[0].session_id;
     *      await SDK3DVerse.joinSession({sessionId, userToken : publicToken, canvas: document.getElementById('display-canvas')});
     * }
     *
     * @async
     */
    findSessions(params) {
        this.setApiVersion('v1');
        this.webAPI.setUserToken(params.userToken);
        return this.webAPI.getSceneSessionlist(params.sceneUUID);
    }

    //------------------------------------------------------------------------------
    /**
     * Get the id of the current session joined or started by a call to [startSession]{@link SDK3DVerse#startSession},
     * [joinSession]{@link SDK3DVerse#joinSession}, or [joinOrStartSession]{@link SDK3DVerse#joinOrStartSession}.
     *
     * @example
     * const sessionId = SDK3DVerse.getSessionId();
     *
     * @returns {string} Current session id.
     */
    getSessionId() {
        return this.webAPI.sessionId;
    }

    /**
     * This function tells 3dverse that you want to create or join a session,
     * and returns the connection info you need to get started.
     * @private
     *
     * @async
     * @param {object} params
     * @param {string} params.userToken Your 3dverse user token or public token.
     * @param {string} [params.sceneUUID] The ID for the scene you wish to open. You must provide either a `sceneUUID` or a `sessionId`.
     * @param {string} [params.sessionId] The ID for the session you wish to join. You must provide either a `sceneUUID` or a `sessionId`.
     * @param {boolean} [params.joinExisting=false] Set to true to try to join an existing session first. For use with `sceneUUID`. Ignored if `sessionId` is provided.
     * @param {boolean} [params.isTransient=false] Set to true to create or join a transient session. All changes that would be normally persistent become transient.
     * @param {SessionConstraints} [params.constraints] Constraints for the session that 3dverse might try to join. For use with `sceneUUID`. Ignored if `sessionId` is provided.
     * @param {object} params.loadingOverlay - The loading overlay callbacks
     * @param {Function} params.loadingOverlay.updateStatusText
     * @param {Function} params.loadingOverlay.removeOverlay
     * @returns {object} `sessionConnectionInfo` object to pass to [start]{@link SDK3DVerse#start}
     */
    getSessionConnectionInfo(params) {
        try {
            if (!params.sceneUUID && !params.sessionId) {
                throw new Error('A sceneUUID or a sessionId must be provided.');
            }
            this.setApiVersion('v1');
            this.webAPI.setUserToken(params.userToken);
            if (params.sessionId) {
                if (params.sceneUUID) {
                    console.warn('Ignoring sceneUUID param since a sessionId was provided.');
                }
                return this.webAPI.joinSession(params.sessionId);
            } else if (params.joinExisting) {
                return this.webAPI.createOrJoinSession(params.sceneUUID, {
                    isTransient: params.isTransient,
                    constraints: params.constraints,
                });
            } else {
                return this.webAPI.createSession(params.sceneUUID, {
                    isTransient: params.isTransient,
                });
            }
        } catch (err) {
            params.loadingOverlay.updateStatusText(err?.message || "Error connecting to 3dverse");
            throw err;
        }
    }

    /**
     * Call this function after getting `sessionConnectionInfo` from
     * [getSessionConnectionInfo]{@link SDK3DVerse#getSessionConnectionInfo},
     * to start streaming a 3dverse session to a canvas element in your app.
     * @private
     *
     * @param {object} params
     * @param {object} params.sessionConnectionInfo The connection info returned by [getSessionConnectionInfo]{@link SDK3DVerse#getSessionConnectionInfo}.
     * @param {HTMLCanvasElement} params.canvas The canvas element to display the stream, whose size will be set by its parent.
     * @param {boolean} [params.connectToEditor=true] Set to true to enable making transient or persistent edits to the scene graph.
     * @param {'no' | 'yes' | 'on-assets-loaded'} [params.startSimulation='no'] 'yes' to start simulation after connecting, 'on-assets-loaded' to wait for assets to load first.
     * @param {ViewportInfo} [params.viewportProperties] Additional viewport settings (such as the controller to use).
     * @param {number} [params.maxDimension=1920] Limit the rendered resolution to a maximum dimension.
     * @param {Function} [params.onConnectingToEditor] Callback to know when the editor connection has started (to give more detailed loading feedback to users).
     * @param {Function} [params.onLoadingAssets] Callback to know when we're connected and now waiting for assets to finish loading (to give more detailed loading feedback to users). Will *only* be called if `startSimulation` is 'on-assets-loaded', and assets are not yet loaded after editor connection.
     * @param {string|float} [params.defaultCameraSpeed='auto'] - The speed of the camera controller to set after the session is joined. The 'auto' value uses the scene aabb to determine the most suitable speed, or it can be set to a float value in meters per second.
     * @param {boolean} [params.hardwareDecoding=true] - Set to true to enable hardware decoding of video streams
     * @param {object} params.loadingOverlay - The loading overlay callbacks
     * @param {Function} params.loadingOverlay.updateStatusText
     * @param {Function} params.loadingOverlay.removeOverlay
     *
     */
    async start(params) {
        const {
            sessionConnectionInfo,
            canvas,
            createDefaultCamera = true,
            connectToEditor = true,
            startSimulation = 'no',
            viewportProperties = {},
            maxDimension = 1920,
            onConnectingToEditor = () => {},
            onLoadingAssets = () => {},
            hardwareDecoding = true,
            loadingOverlay: { updateStatusText, removeOverlay },
        } = params;

        try {
            await this.onClose();

            this.notifier.once('onFrameDecoded', removeOverlay);

            if (!connectToEditor) {
                this.streamerConfig.editorTimeout = 0;
            } else if (!this.streamerConfig.editorTimeout) {
                this.streamerConfig.editorTimeout =
                    SDK3DVerse.defaultStreamerConfig.editorTimeout;
            }

            const cameraCreationPromise = createDefaultCamera ?
                new Promise(onCameraCreation => {
                    this.setViewports([
                        {
                            ...SDK3DVerse.defaultStreamerConfig.viewports[0],
                            ...viewportProperties,
                            onCameraCreation,
                        }
                    ]);
                }) : this.setViewports(null);

            /**
             * This function sets the resolution with a max rendering resolution of
             * 1920px then adapts the scale appropriately for the canvas size.
             */
            const reconfigureResolution = () => {
                const canvasContainer = canvas.parentElement;
                if (!canvasContainer) {
                    throw new Error(
                        'Unexpected detached canvas. Must have parent element.'
                    );
                }

                const { width, height } = canvasContainer.getBoundingClientRect();
                if (!width || !height) {
                    throw new Error(
                        'Canvas parent element must have nonzero dimensions.'
                    );
                }

                const largestDim = Math.max(width, height);
                const scale =
                    largestDim > maxDimension ? maxDimension / largestDim : 1;

                let w = Math.floor(width);
                let h = Math.floor(height);
                const aspectRatio = w / h;

                if (w > h) {
                    // landscape
                    w = Math.floor(aspectRatio * h);
                } else {
                    // portrait
                    h = Math.floor(w / aspectRatio);
                }

                this.setResolution(w, h, scale);
            };

            reconfigureResolution();
            /** @type {number | null} */
            let debounceResizeTimeout = null;
            this.resizeObserver = new ResizeObserver(() => {
                if (debounceResizeTimeout) {
                    clearTimeout(debounceResizeTimeout);
                }
                debounceResizeTimeout = setTimeout(() => {
                    reconfigureResolution();
                    debounceResizeTimeout = null;
                }, 100);
            });
            this.resizeObserver.observe(canvas.parentElement);

            this.setupDisplay(canvas);

            const { defaultCameraSpeed } = params;
            if(defaultCameraSpeed === 'auto' || !isNaN(defaultCameraSpeed))
            {
                this.streamerConfig.defaultCameraSpeed = params.defaultCameraSpeed;
            }

            updateStatusText('Starting FTL engine...');
            await this.startStreamer(sessionConnectionInfo, hardwareDecoding);

            if (connectToEditor) {
                updateStatusText('Connecting to Livelink...');
                onConnectingToEditor();
                await this.connectToEditor();
            }

            if (startSimulation === 'yes') {
                this.engineAPI.startSimulation();
            }

            if (startSimulation === 'on-assets-loaded') {
                if (!this.streamer.areAssetsLoaded) {
                    updateStatusText('Loading assets in scene...');
                    onLoadingAssets();
                    await new Promise((resolve) => {
                        const onAssetsLoadedChanged = () => {
                            if (this.streamer.areAssetsLoaded) {
                                resolve();
                                this.notifier.off(
                                    'onAssetsLoadedChanged',
                                    onAssetsLoadedChanged,
                                );
                            }
                        };
                        this.notifier.on(
                            'onAssetsLoadedChanged',
                            onAssetsLoadedChanged,
                        );
                    });
                }

                this.engineAPI.startSimulation();
            }

            updateStatusText('Connected.');

            await cameraCreationPromise;
        } catch (err) {
            updateStatusText(err?.message || "Error connecting to 3dverse");
            throw err;
        }
    }

    /**
     * Create a new 3dverse session and begin streaming to a canvas element in
     * your app.
     *
     * Once this function is resolved, it is guaranteed that the renderer has connected
     * and that the editor has connected (if `params.connectToEditor` was true).
     * Once the renderer and editor have connected, then [engineAPI]{@link SDK3DVerse.engineAPI},
     * [cameraAPI]{@link SDK3DVerse.engineAPI.cameraAPI}, {@link Entity}, {@link EntityTemplate}
     * and {@link Viewport} can be used.
     *
     * @param {object} params
     * @param {string} params.sceneUUID The UUID for the scene you wish to open
     * @param {string} params.userToken Your 3dverse user token or public token
     * @param {HTMLCanvasElement} params.canvas The [HTMLCanvasElement]{@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement} that will be used to display the stream, whose size will be set by its parent
     * @param {boolean} [params.isTransient=false] Set to true to create a transient session. All changes that would be normally persistent become transient
     * @param {boolean} [params.connectToEditor=true] Set to true to enable making transient or persistent edits to the scene graph
     * @param {'no' | 'yes' | 'on-assets-loaded'} [params.startSimulation='no'] 'yes' to start simulation after connecting, 'on-assets-loaded' to wait for assets to load first
     * @param {boolean} [params.createDefaultCamera=true] Create default camera
     * @param {ViewportInfo} [params.viewportProperties] Additional viewport settings (such as the controller to use). Not used if createDefaultCamera is false
     * @param {number} [params.maxDimension=1920] Limit the rendered resolution to a maximum dimension
     * @param {Function} [params.onStartingStreamer] Callback to know when the streamer connection has begun (to give more detailed loading feedback to users)
     * @param {Function} [params.onConnectingToEditor] Callback to know when the editor connection has started (to give more detailed loading feedback to users)
     * @param {Function} [params.onLoadingAssets] Callback to know when we're connected and now waiting for assets to finish loading (to give more detailed loading feedback to users). Will *only* be called if `startSimulation` is 'on-assets-loaded', and assets are not yet loaded after editor connection
     * @param {string|float} [params.defaultCameraSpeed='auto'] - The speed of the camera controller to set after the session is joined. The 'auto' value uses the scene aabb to determine the most suitable speed, or it can be set to a float value in meters per second
     * @param {boolean} [params.hardwareDecoding=true] - Set to true to enable hardware decoding of video streams
     * @param {boolean} [params.showLoadingOverlay=true] - Set to false to skip the loading overlay
     *
     * @example
     * // This starts a session on a scene with sceneUUID. Frames will begin streaming to
     * // the HTML canvas element with id 'my-canvas-id'.
     * // A viewport covering the entire canvas will be created, and a default camera will be created
     * // and associated to the viewport. The camera will be set up with the editor camera controller,
     * // and so the user will be able to navigate in the scene.
     * await SDK3DVerse.startSession(
     * {
     *      sceneUUID,
     *      userToken: publicToken,
     *      canvas: document.getElementById('my-canvas-id')
     * });
     *
     * @fires onConnectionClosed
     */
    async startSession(params) {
        const loadingOverlay = createLoadingOverlay(params.canvas.parentElement, params.showLoadingOverlay);
        const sessionConnectionInfo = await this.getSessionConnectionInfo({ ...params, loadingOverlay, joinExisting: false });
        params.onStartingStreamer?.();
        await this.start({ ...params, loadingOverlay, sessionConnectionInfo });
    }

    /**
     * Join an existing 3dverse session and begin streaming to a canvas element
     * in your app. If the session with the provided `params.sessionId` was not found, an error
     * is thrown.
     *
     * Once this function is resolved, it is guaranteed that the renderer has connected
     * and that the editor has connected (if `params.connectToEditor` was true).
     * Once the renderer and editor have connected, then [engineAPI]{@link SDK3DVerse.engineAPI},
     * [cameraAPI]{@link SDK3DVerse.engineAPI.cameraAPI}, {@link Entity}, {@link EntityTemplate}
     * and {@link Viewport} can be used.
     *
     * @param {object} params
     * @param {string} params.sessionId The ID for the session you wish to join. Can be retrieved using [findSessions]{@link SDK3DVerse#findSessions}
     * @param {string} params.userToken Your 3dverse user token or public token
     * @param {HTMLCanvasElement} params.canvas The [HTMLCanvasElement]{@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement} that will be used to display the stream, whose size will be set by its parent
     * @param {boolean} [params.connectToEditor=true] Set to true to enable making transient or persistent edits to the scene graph
     * @param {boolean} [params.createDefaultCamera=true] Create default camera
     * @param {ViewportInfo} [params.viewportProperties] Additional viewport settings (such as the controller to use). Not used if createDefaultCamera is false
     * @param {number} [params.maxDimension=1920] Limit the rendered resolution to a maximum dimension
     * @param {Function} [params.onStartingStreamer] Callback to know when the streamer connection has begun (to give more detailed loading feedback to users)
     * @param {Function} [params.onConnectingToEditor] Callback to know when the editor connection has started (to give more detailed loading feedback to users)
     * @param {string|float} [params.defaultCameraSpeed='auto'] - The speed of the camera controller to set after the session is joined. The 'auto' value uses the scene aabb to determine the most suitable speed, or it can be set to a float value in meters per second
     * @param {boolean} [params.hardwareDecoding=true] - Set to true to enable hardware decoding of video streams
     * @param {boolean} [params.showLoadingOverlay=true] - Set to false to skip the loading overlay
     *
     * @example
     * // This joins an existing session with sessionId. Frames will begin streaming to
     * // the HTML canvas element with id 'my-canvas-id'.
     * // A viewport covering the entire canvas will be created, and a default camera will be created
     * // and associated to the viewport. The camera will be set up with the editor camera controller,
     * // and so the user will be able to navigate in the scene.
     * const sessions = await SDK3DVerse.findSessions({userToken : publicToken, sceneUUID});
     * const sessionId = sessions[0].session_id;
     * try
     * {
     *      await SDK3DVerse.joinSession(
     *      {
     *          sessionId,
     *          userToken: publicToken,
     *          canvas: document.getElementById('my-canvas-id')
     *      });
     * }
     * catch(e)
     * {
     *      console.log(`Session with id: ${sessionId} was not found.`);
     * }
     *
     * @fires onConnectionClosed
     */
    async joinSession(params) {
        const loadingOverlay = createLoadingOverlay(params.canvas.parentElement, params.showLoadingOverlay);
        const sessionConnectionInfo = await this.getSessionConnectionInfo({ ...params, loadingOverlay });
        params.onStartingStreamer?.();
        await this.start({ ...params, loadingOverlay, sessionConnectionInfo });
    }

    /**
     * Join an existing 3dverse session if one exists for a given scene (with
     * optional constraints), or else create one, and begin streaming to a
     * canvas element in your app.
     *
     * Once this function is resolved, it is guaranteed that the renderer has connected
     * and that the editor has connected (if `params.connectToEditor` was true).
     * Once the renderer and editor have connected, then [engineAPI]{@link SDK3DVerse.engineAPI},
     * [cameraAPI]{@link SDK3DVerse.engineAPI.cameraAPI}, {@link Entity}, {@link EntityTemplate}
     * and {@link Viewport} can be used.
     *
     * @param {object} params
     * @param {string} params.sceneUUID The UUID for the scene you wish to open
     * @param {string} params.userToken Your 3dverse user token or public token
     * @param {HTMLCanvasElement} params.canvas The [HTMLCanvasElement]{@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement} element that will be used to display the stream, whose size will be set by its parent
     * @param {SessionConstraints} [params.constraints] Constraints for the session that 3dverse might try to join
     * @param {boolean} [params.isTransient=false] Set to true to create or join a transient session. All changes that would be normally persistent become transient
     * @param {boolean} [params.connectToEditor=true] Set to true to enable making transient or persistent edits to the scene graph
     * @param {'no' | 'yes' | 'on-assets-loaded'} [params.startSimulation='no'] 'yes' to start simulation after connecting to new session, 'on-assets-loaded' to wait for assets to load first
     * @param {boolean} [params.createDefaultCamera=true] Creates default camera
     * @param {ViewportInfo} [params.viewportProperties] Additional viewport settings (such as the controller to use). Not used if createDefaultCamera is false
     * @param {number} [params.maxDimension=1920] Limit the rendered resolution to a maximum dimension
     * @param {Function} [params.onFindingSession] Callback to know when we've begun looking for a session to join
     * (to give more detailed loading feedback to users). This callback will be triggered immediately, but can be
     * triggered again (along with the other callbacks) if a session connection fails and we have to restart
     * @param {Function} [params.onStartingStreamer] Callback to know when the streamer connection has begun (to give more detailed loading feedback to users)
     * @param {Function} [params.onConnectingToEditor] Callback to know when the editor connection has started (to give more detailed loading feedback to users)
     * @param {Function} [params.onLoadingAssets] Callback to know when we're connected and now waiting for assets to finish loading (to give more detailed loading feedback to users). Will *only* be called if `startSimulation` is 'on-assets-loaded', and assets are not yet loaded after editor connection
     * @param {string|float} [params.defaultCameraSpeed='auto'] - The speed of the camera controller to set after the session is joined. The 'auto' value uses the scene aabb to determine the most suitable speed, or it can be set to a float value in meters per second
     * @param {boolean} [params.hardwareDecoding=true] - Set to true to enable hardware decoding of video streams
     * @param {boolean} [params.showLoadingOverlay=true] - Set to false to skip the loading overlay
     *
     * @returns {boolean} True if the session was started, false if the session was joined.
     *
     * @example
     * // This joins or starts a session on a scene with sceneUUID. Frames will begin streaming to
     * // the HTML canvas element with id 'my-canvas-id'.
     * // A viewport covering the entire canvas will be created, and a default camera will be created
     * // and associated to the viewport. The camera will be set up with the editor camera controller,
     * // and so the user will be able to navigate in the scene.
     * const sessionStarted = await SDK3DVerse.joinOrStartSession(
     * {
     *      sceneUUID,
     *      userToken: publicToken,
     *      canvas: document.getElementById('my-canvas-id')
     * });
     *
     * @fires onConnectionClosed
     */
    async joinOrStartSession(params) {
        const loadingOverlay = createLoadingOverlay(params.canvas.parentElement, params.showLoadingOverlay);
        let retriesLeft = 2;
        let startFailed = false;
        do {
            startFailed = false;
            try {
                loadingOverlay.updateStatusText('Connecting to 3dverse...');
                params.onFindingSession?.();
                const sessionConnectionInfo =
                    await this.getSessionConnectionInfo({ ...params, loadingOverlay, joinExisting: true });
                params.onStartingStreamer?.();
                const isSessionCreator = Boolean(sessionConnectionInfo.sessionCreated);
                await this.start({
                    ...params,
                    loadingOverlay,
                    sessionConnectionInfo,
                    startSimulation: isSessionCreator ? params.startSimulation : 'no',
                });
                return isSessionCreator;
            } catch (err) {
                if (retriesLeft) {
                    startFailed = true;
                    console.warn(err);
                } else {
                    throw err;
                }
            }
        } while (startFailed && retriesLeft--);
    }

    /**
     * Returns whether the session is transient or not. If it's transient then all changes that would be normally persistent become transient.
     * @returns {boolean|undefined} True if the session is transient. If not connected to a session, then it returns undefined.
     */
    isTransientSession() {
        return this.engineAPI.editorAPI.isTransientSession;
    }
}

export default SDK3DVerse;


//------------------------------------------------------------------------------
/**
 * [EventEmitter]{@link https://nodejs.org/api/events.html#events_class_eventemitter} object. Used to subscribe to all events emitted from the SDK.
 * These events include [onEntityCreated]{@link event:onEntityCreated}, [onEntitiesUpdated]{@link event:onEntitiesUpdated}, [onFramePostRender]{@link event:onFramePostRender}, and more.
 * You can register and deregister callbacks to events with `on` and `off`.
 *
 * @example
 * function onEntityCreated(createdEntity)
 * {
 *      console.log(createdEntity.getName(), 'was created!');
 * }
 * // Register callback
 * SDK3DVerse.notifier.on('onEntityCreated', onEntityCreated);
 * // Unregister callback
 * SDK3DVerse.notifier.off('onEntityCreated', onEntityCreated);
 *
 * @namespace SDK3DVerse.notifier
 */

//------------------------------------------------------------------------------
/**
 * Register a callback function to the event named `eventName`. When the specified event is emitted, all registered callback functions will be called.
 *
 * @param {string} eventName - The name of the event
 * @param {function} callback  - The callback function. What the function takes as parameter(s) depends on the parameters of the event. See events below
 *
 * @returns {EventEmitter} Returns a reference to the [EventEmitter]{@link https://nodejs.org/api/events.html#events_class_eventemitter} , so that calls can be chained.
 *
 * @method SDK3DVerse.notifier#on
 */
function on()
{
    //This function is not used. It's used to generated documentation only.
}

//------------------------------------------------------------------------------
/**
 * Unregister callback function to the event named `eventName`. When the specified event is emitted, the unregistered callback functions will no longer be called.
 *
 * @param {string} eventName - The name of the event
 * @param {function} callback  - The callback function. What the function takes as parameter(s) depends on the parameters of the event. See events below
 *
 * @returns {EventEmitter} Returns a reference to the [EventEmitter]{@link https://nodejs.org/api/events.html#events_class_eventemitter} , so that calls can be chained.
 *
 * @method SDK3DVerse.notifier#off
 */
function off()
{
    //This function is not used. It's used to generated documentation only.
}

//------------------------------------------------------------------------------
/**
 * Event emitted when the client has disconnected from the session.
 *
 * Client can be disconnected from the session if the client is idle for too long
 * (see [setInactivityCallback]{@link SDK3DVerse#setInactivityCallback} for more details),
 * or after an explicit call to [disconnectFromSession]{@link SDK3DVerse#disconnectFromSession}.
 *
 * @example
 * SDK3DVerse.notifier.on('onConnectionClosed', () => {
 *      console.log('Client disconnected from session.');
 * });
 *
 * @event onConnectionClosed
 */

//------------------------------------------------------------------------------
/**
 * Callback passed to [setInactivityCallback]{@link SDK3DVerse#setInactivityCallback}.
 *
 * @function InactivityCallback
 * @param {function} resumeCallback Callback that takes no parameters and should be called
 *                                 if the inactive client should not be disconnected.
 *                                  Needs to be called within 30 seconds of the start of InactivityCallback
 *                                  otherwise client will be disconnected anyways.
 */
